import React, { useCallback, useRef } from 'react';

export function IntersectionComponent(props) {
  const ref = useRef(null);

  const intersectionRef = useCallback((node) => {
    if (!node) return;
    ref.current = node;
    const observer = new IntersectionObserver(callback, {
      root: null,
      threshold: 0.5,
    });
    node && observer.observe(node);
  }, []);

  function callback(e) {
    const isIntersecting = e?.[0]?.isIntersecting;
    props.onIntersection(isIntersecting);
  }

  if (!props.show) return null;
  return (
    <div ref={intersectionRef} className="flex justify-center mt4 relative">
      {props.Component ? (
        <props.Component style={{ transform: 'translateX(-50%)' }} />
      ) : null}
    </div>
  );
}

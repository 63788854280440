import { useEffect, useState } from 'react';
import AOS from 'aos';
import { useSelector } from 'react-redux';
import { deviceWidth } from '@/utils/deviceWidth';
import { removeDsAdsRouteLink } from '@/utils/removeDsAdsRouteLink';
import MiddleBodyMobileReasonModal from '../../../DigitalShowroomAds/MiddleBodyMobileReasonModal';
import { AdContainer } from '../../MiddleBody6/Hybrid.styles';
import { InfoIcon } from '@/assets/svgExports/InfoIcon';
import ReasonModalHOC from '../../../DigitalShowroomAds/ReasonModalHOC';
import Image from 'next/image';
import { MAX_MOBILE_WIDTH } from '../../../../../utils/constants';
import { useSSRSelector } from '@/redux/ssrStore';
import checkIfThemePreview from '@/utils/checkIfThemePreview';

const mobileColumnNumber = 2; // Number of columns in mobile view for grid
const adRepeatGridLinesConstant = 3; // approx repeating ad after how many grid lines are spanned
const EvalIndexMultiplier = mobileColumnNumber * adRepeatGridLinesConstant; // helper constant for calculation
let incomingNewDataPoints = 0; // helper variable for new productIds that will come

function MobileDsAds(props) {
  const [showAdInfo, setShowAdInfo] = useState({});
  const [dataIdForAds, setDataIdForAds] = useState([]);

  const { catalog_items } = useSelector((state) => ({
    catalog_items: state.catalogReducer.catalog_items,
  }));

  const storeData = useSSRSelector((state) => state.storeReducer.store);
  const isPreview = checkIfThemePreview();

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    initiateShowAdObject();
  }, [dataIdForAds]);

  useEffect(() => {
    setDataIdForAds([]);
    getMobileDataIdForAds();
  }, [catalog_items]);

  /**
   * product.id -> reasonModal Toggled
   * @param {*} id - product id to be toggled
   * @param {*} isReasonModalOpen  - Toggle status of ReasonModal
   */

  function toggleMobileAdReasonModal(id, isReasonModalOpen) {
    const tempShowAd = { ...showAdInfo };
    tempShowAd[id] = isReasonModalOpen;
    setShowAdInfo(tempShowAd);
  }

  /**
   * maps product-id's to show if reasonModal
   * corresponding to Ad to false initially
   */
  function initiateShowAdObject() {
    const tempShowAdObject = {};
    for (let i = 0; i < dataIdForAds.length; i++) {
      tempShowAdObject[dataIdForAds[i]] = false;
    }
    setShowAdInfo(tempShowAdObject);
  }
  /**
   * Appends New ProductId in DataIdArray
   * that will come after processing all items inside Category
   * @param {*} item
   * @param {*} remainder
   */
  function setDataIdArray(item, remainder) {
    if (incomingNewDataPoints === 0) {
      const evaluatedIndex = 0;
      setDataIdForAds((dataIdForAds) => [
        ...dataIdForAds,
        catalog_items[item][evaluatedIndex]?.id,
      ]);
    } else {
      while (incomingNewDataPoints) {
        const evaluatedIndex = incomingNewDataPoints * EvalIndexMultiplier - 1;
        setDataIdForAds((dataIdForAds) => [
          ...dataIdForAds,
          catalog_items[item][evaluatedIndex - remainder]?.id,
        ]);
        incomingNewDataPoints--;
      }
    }
  }

  /**
   * the function Aprroximates place ad after every 3 CSS Grid Row Span
   * sets {array} -> product.id Below which the ads are placed.
   */
  function getMobileDataIdForAds() {
    let totalGridRowLines = 0;
    Object.keys(catalog_items).map((item) => {
      totalGridRowLines += Math.ceil(catalog_items[item].length / mobileColumnNumber);
      if (Math.floor(totalGridRowLines / adRepeatGridLinesConstant) !== 0) {
        const totalLinesInCategory = Math.ceil(
          catalog_items[item].length / mobileColumnNumber
        );
        const remainder = catalog_items[item].length % mobileColumnNumber;
        incomingNewDataPoints = Math.floor(
          totalLinesInCategory / adRepeatGridLinesConstant
        );
        setDataIdArray(item, remainder);
        totalGridRowLines = 0;
      }
    });
  }

  return (
    !isPreview && (
      <>
        {deviceWidth <= MAX_MOBILE_WIDTH &&
        dataIdForAds.includes(props.data.id) &&
        storeData.store_info?.non_premium_ad_url_mobile &&
        !storeData.theme?.is_demo ? (
          <AdContainer data-aos="fade-right" data-aos-duration="1000">
            <div className="flex flex-column digital-showroom__ads pb20px">
              <div
                className="flex container-width pb10px"
                onClick={() => toggleMobileAdReasonModal(props.data.id, true)}
              >
                <div className="flex f12px o-50 mb6px">Why I am seeing this?</div>
                <div className="ml4px f10px">
                  <InfoIcon size={16} secondaryColor={'#838383'} />
                </div>
              </div>
              {showAdInfo[props.data.id] && (
                <ReasonModalHOC
                  reasonModal={MiddleBodyMobileReasonModal}
                  toggleAdReasonModal={() =>
                    toggleMobileAdReasonModal(props.data.id, false)
                  }
                  className={'middle-body-mobile-reason-modal'}
                  ml={-1.15}
                  translateX={9.5}
                  translateY={-1}
                />
              )}
              <div
                className="flex gif-height items-start pointer"
                onClick={removeDsAdsRouteLink}
              >
                <Image
                  src={storeData.store_info?.non_premium_ad_url_mobile}
                  width={630}
                  height={187}
                ></Image>
              </div>
            </div>
          </AdContainer>
        ) : null}
      </>
    )
  );
}

export default MobileDsAds;

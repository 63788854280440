import { useEffect, useState } from 'react';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';

const MAX_CARD_WIDTH = 450;
// const MIN_CARD_WIDTH = 200;
const MAX_CARD_HEIGHT = 450;

export function useProductCardElements() {
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const { infoList, isEnabled: isProductCardEnabled } = globalStyle?.productCard || {
    infoList: [],
    isEnabled: false,
  };
  const imageElement = infoList?.find((item) => item.type === 'image');
  const layoutElement = infoList?.find((item) => item.type === 'layout');

  return {
    isProductCardEnabled,
    layoutElement,
    imageElement,
    infoList,
    aspectRatio: imageElement?.config?.aspectRatio || imageElement?.config?.aspectRation,
  };
}

export function useProductCardDimensions({ imageWidth }) {
  const { aspectRatio } = useProductCardElements();
  const [cardSelfDimensions, setCardSelfDimensions] = useState({
    cardHeight: 0,
    cardWidth: 0,
  });

  useEffect(() => {
    if (aspectRatio) {
      const aspectRatios = (() => {
        const ratio = aspectRatio?.split(':') || [1, 1];
        return {
          heightMultiplier: +ratio[1],
          widthMultiplier: +ratio[0],
        };
      })();
      if (imageWidth) {
        let calculatedCardWidth =
          imageWidth > MAX_CARD_WIDTH
            ? MAX_CARD_WIDTH
            : // : imageWidth < MIN_CARD_WIDTH
              //   ? MIN_CARD_WIDTH
              imageWidth;

        let calculatedCardHeight =
          calculatedCardWidth *
          (aspectRatios.heightMultiplier / aspectRatios.widthMultiplier);

        if (calculatedCardHeight > MAX_CARD_HEIGHT) {
          calculatedCardHeight = MAX_CARD_HEIGHT;
          calculatedCardWidth =
            calculatedCardHeight *
            (aspectRatios.widthMultiplier / aspectRatios.heightMultiplier);
        }

        setCardSelfDimensions((data) => ({
          ...data,
          cardWidth: calculatedCardWidth,
          cardHeight: calculatedCardHeight,
        }));
      }
    }
  }, [imageWidth, aspectRatio]);

  return { cardSelfDimensions };
}

import React from 'react';
import { ListingHeading } from '../Hybrid.styles';
import { ProductListWrapper } from 'src/components/DesignSystem/Widgets2.0/ProductListWrapper';

export const CatalogItem = (props) => {
  const { item, catalog_items, onItemsImageClick, showATCBtn } = props;

  return (
    <div key={`listing-${item.id}`}>
      <ListingHeading id={`listing-${item.id}`}>
        {item.name || 'Available Items'}
      </ListingHeading>
      {/* <Listing> */}
      <ProductListWrapper
        productList={catalog_items[item.id]}
        oldCardConfigs={{
          showButton: showATCBtn,
          onItemClick: onItemsImageClick,
          noTags: true,
        }}
      />
      {/* </Listing> */}
    </div>
  );
};

import { fetchCatalogItemsByCategoryId } from '@/redux/actions';
import store from '@/redux/store';

export const getProducts = (id, type, pageNo = 0, storeId) => {
  const staleStoreId = store.getState().storeReducer.store.store_id;
  const dispatch = store.dispatch;
  const sID = storeId || staleStoreId;

  if (!sID) return;
  return new Promise((resolve, reject) => {
    dispatch(
      fetchCatalogItemsByCategoryId(
        {
          store_id: sID,
          category_id: id,
          category_type: type,
          page_no: pageNo,
        },
        (status, isNextPage) =>
          status ? resolve({ status, isNextPage }) : reject(status)
      )
    );
  });
};

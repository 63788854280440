import * as React from 'react';
// eslint-disable-next-line import/named
import Snackbar, { SnackbarOrigin, SnackbarProps } from '@mui/material/Snackbar';
// eslint-disable-next-line import/named
import { snackbarContentClasses } from '@mui/material';
import { TickFill } from '@/assets/svgExports/TickFill';
import { useGetDeviceType } from 'src/hooks/useGetDeviceType';

interface State extends SnackbarOrigin {
  open?: boolean;
}

interface ISnackbarConfig extends State {
  message?: React.ReactNode;
  type?: 'success' | 'error' | '';
}

export type ISnackBarConfig = Partial<ISnackbarConfig>;

export interface ISnackProps extends SnackbarProps {
  config?: ISnackBarConfig;
  handleSnackbarClose?: (...x: any[]) => void;
}

export function CustomizedSnackbar({
  config,
  autoHideDuration = 4500,
  handleSnackbarClose,
}: ISnackProps) {
  const { isMobile } = useGetDeviceType();
  const [state, setState] = React.useState<ISnackBarConfig>({
    open: false,
    message: '',
    vertical: 'top',
    horizontal: 'right',
    type: 'success',
  });
  const { vertical, horizontal } = state;
  const { open, message } = config;

  React.useEffect(() => {
    setState({ ...state, ...config });
  }, [config]);

  const icon = (() => {
    switch (state.type) {
      case 'success':
        return <TickFill width={17} height={17} color={'#32B272'} />;
      default:
        return null;
    }
  })();

  return (
    <Snackbar
      sx={{
        ...(horizontal === 'center' && isMobile
          ? { left: '10% !important', right: '10% !important' }
          : {}),
        [`& .${snackbarContentClasses.root}`]: {
          minWidth: 'auto',
          padding: '0px 0px',
        },
        [`& .${snackbarContentClasses.message}`]: {
          width: '100%',
          padding: '15px 20px',
          borderRadius: '6px',
          backgroundColor: '#111C36',
          boxShadow: '0px 4px 4px 0px #00000040',
        },
      }}
      anchorOrigin={{ vertical, horizontal }}
      autoHideDuration={autoHideDuration}
      open={open}
      onClose={handleSnackbarClose}
      message={
        <div className="tw-flex tw-items-center tw-justify-center tw-gap-[8px]">
          {icon}
          <p className=" tw-m-0 tw-font-inter ">{message}</p>
        </div>
      }
      key={vertical + horizontal}
    />
  );
}

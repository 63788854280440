import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useGetDeviceType } from '@/hooks/useGetDeviceType';
import { CARD_SKELETON } from '@/utils/constants';
import { useItemClick } from 'src/hooks/useItemClick';
import { MobileDsAds } from '../../MiddleBody/MiddleBody5/components';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import classNames from 'classnames';
import { getCartWrapperConfigs } from '../ProductCard/utils';
import {
  CustomizedSnackbar,
  ISnackBarConfig,
} from 'src/components/WidgetMaker/WidgetDnD/WIdgetPropertiesBasic/components/Snackbar/Snackbar';
import { useProductCardDimensions, useProductCardElements } from './utils';
import VirtualisedGridList from 'src/components/VirtualisedList';


const Card = dynamic(() => import('../../Card').then((mod) => mod.Card));
const ProductCard = dynamic(() =>
  import('../ProductCard').then((mod) => mod.ProductCard)
);
const AdvanceCard = dynamic(() =>
  import('../../AdvanceCard/AdvanceCard').then((mod) => mod.default)
);

interface IProductListWrapper {
  children?: any;
  productList?: any[];
  overrideGlobalBuilderProductCard?: boolean;
  // Note -> when passing `virtualised-grid` make sure to pass the items as an array of `FlattenRowDataType`.
  renderType?: 'grid' | 'carousel' | 'virtualised-grid';
  oldCardConfigs?: {
    noTags?: boolean;
    showButton?: boolean;
    onItemClick?: any;
    showCategoryCard?: boolean;
    noTagsReadState?: 'fromProp' | 'fromLength';
  };
  hideLoaderSkeleton?: boolean;
  renderAdvancedCard?: boolean;
  skeletonCount?: number;
  CustomCardComponent?: any;
  customClass?: string;
  calculateSelfDimensions?: boolean;
}

// Product list is put got like this to get category wise data etc.
// eslint-disable-next-line react/display-name
export const ProductListWrapper = forwardRef(({
  children = null,
  productList = [],
  oldCardConfigs = {
    showButton: true,
    noTags: false,
    showCategoryCard: false,
    noTagsReadState: 'fromProp',
  },
  hideLoaderSkeleton = false,
  skeletonCount = 3,
  overrideGlobalBuilderProductCard = false,
  renderAdvancedCard = false,
  CustomCardComponent = null,
  customClass,
  calculateSelfDimensions = false,
  renderType = 'grid',
}: IProductListWrapper, productListWrapperReference) => {
  const {
    widgetContextState: { isBuilder, previewDevice },
  } = useWidgetDndContextData();
  const { layoutElement, infoList, isProductCardEnabled } = useProductCardElements();
  const showCustomProductCard =
    isProductCardEnabled && infoList?.length && !overrideGlobalBuilderProductCard
      ? true
      : false;
  const layoutElementConfig = layoutElement?.config;
  const cardWrapperConfigs = getCartWrapperConfigs(layoutElementConfig);
  const productItemContainerRef = useRef(null);
  const virtuosoGridRef = useRef(null);
  const { deviceType } = useGetDeviceType();
  const isMobile =
    (isBuilder ? previewDevice === 'mobile' : false) || deviceType === 'mobile';
  const [listDimensions, setListDimensions] = useState({
    containerWidth: 0,
  });
  const columnsMobile = layoutElementConfig?.numColumnsMobile || 2;
  const coumnsDesktop = layoutElementConfig?.numColumnsDesktop || 3;
  const gaps = (() => {
    const gap = { columnGap: 20, rowGap: 30 };
    if (!showCustomProductCard) return gap;
    if (!isMobile && !cardWrapperConfigs.isCardLayout) {
      gap.columnGap = 28;
      gap.rowGap = 52;
      return gap;
    }
    if (!isMobile && cardWrapperConfigs.isCardLayout) {
      gap.columnGap = 16;
      gap.rowGap = 16;
      return gap;
    }
    if (isMobile && !cardWrapperConfigs.isCardLayout) {
      gap.columnGap = 16;
      gap.rowGap = 32;
      return gap;
    }
    gap.columnGap = 8;
    gap.rowGap = 8;
    return gap;
  })();

  const [onItemClick] = useItemClick();

  const columnNum = isMobile ? columnsMobile : coumnsDesktop;
  const OldCardComponent =
    CustomCardComponent || (!renderAdvancedCard ? Card : AdvanceCard);

  const [snackbarConfig, setSnackbarConfig] = useState<ISnackBarConfig>({
    open: false,
    message: '',
  });

  // extracting the `scrollToIndex` from Virtuoso component and exposing it to parent for the auto scroll functionality
  useImperativeHandle(productListWrapperReference, () => {
    return {
      scrollToIndex: (index) => virtuosoGridRef?.current?.scrollToIndex?.(index),
    };
  });

  useEffect(() => {
    function updateDimensions() {
      const sectionWidth = productItemContainerRef.current?.clientWidth;
      if (sectionWidth) {
        setListDimensions((items) => ({
          ...items,
          containerWidth: sectionWidth,
        }));
      }
    }
    const resObserver = new ResizeObserver(updateDimensions);
    productItemContainerRef.current &&
      resObserver.observe(productItemContainerRef.current);
    updateDimensions();

    return () => {
      productItemContainerRef.current &&
        resObserver.unobserve(productItemContainerRef.current);
    };
  }, []);

  // This is the padding that is added in the card when images is rendered in the card with padding
  // Horizontal Padding is added i.e. 10(leftPad) & 10(rightPad) = 20, will be removed from calculated
  // Image width will be minus these
  const cardWithMarginDimensions =
    cardWrapperConfigs.isCardLayout && !cardWrapperConfigs.isFullWidth
      ? isMobile
        ? 20
        : 28
      : 0;

  const imageWidth = (() => {
    const totalGapWidth = (columnNum - (!isMobile ? 1 : 0)) * gaps.columnGap;
    const oneCardWidth = (listDimensions.containerWidth - totalGapWidth) / columnNum;
    // If not card with padding, then oneCardWidth is same as oneImageWidth
    const oneImgWidth = oneCardWidth - cardWithMarginDimensions;
    return oneImgWidth;
  })();

  const { cardSelfDimensions } = useProductCardDimensions({ imageWidth });
  const cardWidth = cardSelfDimensions.cardWidth + cardWithMarginDimensions;
  // const canFitColumns = cardWidth * columnNum <= listDimensions.containerWidth;
  const canFitColumns =
    cardWidth - gaps.rowGap * columnNum <= listDimensions.containerWidth;


  const snackbarMethods = {
    openSnackbar: (config: ISnackBarConfig) =>
      setSnackbarConfig({ open: true, ...config }),
    hideSnackbar: () =>
      snackbarConfig.open && setSnackbarConfig((data) => ({ ...data, open: false })),
  };

  const renderProductCard = useCallback((index, listItem) => {
    const data = listItem;
    return (
      <React.Fragment key={'items-card-' + data.id}>
        {showCustomProductCard ? (
          <ProductCard
            productData={data}
            itemIndex={index}
            onItemClick={onItemClick}
            configs={{
              cardWrapperConfigs,
              isMobile,
              dimensions: cardSelfDimensions,
              snackbarMethods,
            }}
          />
        ) : (
          <OldCardComponent
            data={data}
            id={`${data.name}-${data.id}`}
            onItemClick={() => onItemClick(data)}
            {...oldCardConfigs}
          />
        )}
        <MobileDsAds data={data} />
      </React.Fragment>
    );
  }, [cardSelfDimensions])

  if (renderType === "virtualised-grid") {
    return (
      <VirtualisedGridList
        ref={virtuosoGridRef}
        listData={productList}
        renderProductCard={renderProductCard}
        virtualisationContainerStyle={{}}
        itemsRenderConfig={{
          columnCount: canFitColumns ? columnNum : columnNum - 1,
          columnGap: gaps.columnGap,
          rowGap: gaps.rowGap,
        }}
      />
    )
  }

  return (
    <article
      ref={productItemContainerRef}
      className={classNames(
        'newProductListingWrapper ',
        renderType === 'grid'
          ? 'tw-grid tw-justify-center tw-justify-items-center'
          : 'no-scrollbar tw-flex tw-overflow-auto',
        customClass
      )}
      style={{
        gridTemplateColumns: `repeat(${canFitColumns ? columnNum : columnNum - 1}, ${
          isMobile
            ? cardWidth > 0
              ? `minmax(10px, ${cardWidth}px)`
              : '1fr'
            : `minmax(10px, ${showCustomProductCard || calculateSelfDimensions ? cardWidth : 220}px)`
        })`,
        columnGap: `${gaps.columnGap}px`,
        rowGap: `${gaps.rowGap}px`,
      }}
    >
      {productList?.length
        ? productList.map((data, index) => renderProductCard(index, data))
        : !hideLoaderSkeleton &&
          skeletonCount && (
            <>
              {new Array(skeletonCount).fill(0).map((_, index) => (
                <Card skeleton skeletonImage={CARD_SKELETON} key={index} />
              ))}
            </>
          )}
      {children}
      <CustomizedSnackbar
        config={{ ...snackbarConfig }}
        handleSnackbarClose={() => setSnackbarConfig((x) => ({ ...x, open: false }))}
      />
    </article>
  );
})

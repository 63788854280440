import { removeDsAdsRouteLink } from '@/utils/removeDsAdsRouteLink';
import React from 'react';

function MiddleBodyMobileReasonModal(props) {
  return (
    <div className="flex flex-column">
      <div>
        <div className="flex f12px pa14px fw6">
          <div className="top-section pb14px">
            You are seeing this Ad because owner of this website is using the free version
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-between items-center pl18px pr18px black-90">
        <div className="flex f12px fw6">
          If you own this website <br></br>then you can remove this.
        </div>
        <div className="f12px" onClick={removeDsAdsRouteLink}>
          <div className="remove-adsection pa12px fw7">Remove this Ad</div>
        </div>
      </div>
    </div>
  );
}

export default MiddleBodyMobileReasonModal;

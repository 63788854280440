import { CLICKED_ITEM_OBJ } from './constants';

export function storeProductClickedDetails() {
  const expiresAt = new Date(new Date().getTime() + 30000);
  const sessionObject = {
    expiresAt: expiresAt,
    itemClickedData: {
      CLICKED_ITEM_X_OFFSET: scrollX,
      CLICKED_ITEM_Y_OFFSET: scrollY,
    },
  };
  sessionStorage.setItem(CLICKED_ITEM_OBJ, JSON.stringify(sessionObject));
}

import React, {
  forwardRef,
  memo,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Virtuoso } from 'react-virtuoso';
import { FlattenRowDataType, ItemTypes, VirtualisedGridListTypes } from './types';
import { Card } from '../DesignSystem/Card';
import { CARD_SKELETON } from 'src/utils/constants';
import cloneDeep from "lodash/cloneDeep"

const INCREASE_VIEWPORT_BY = 0;

/**
 * the main idea behind this component is to treat the rows as ->
 * 1. `components` - which will have full width and can be changed to any component like heading.
 * 2. `sliced-flex-cards` - which is a flex row containing product cards.
 */
// eslint-disable-next-line react/display-name
const VirtualisedGridList = memo(
  forwardRef(
    (
      {
        listData,
        renderProductCard,
        itemsRenderConfig,
        virtualisationContainerStyle,
        useWindowScroll=true,
        defaultItemHeight,
      }: VirtualisedGridListTypes,
      virtualisedGridListReference
    ) => {
      const virtuosoGridRef = useRef(null);

      /**
       * to track previous value of range.
       * keys can have any value (just to maintain an entry).
       * this will help in reducing calls for `intersectionCallback`
       * check implementation of `handleRangeChanged`
       */
      const rowChangeTrackerRef = useRef({});

      const isItemCardType = (itemType: ItemTypes) => {
        return itemType === 'product-card' || itemType === 'product-card-skeleton';
      };

      /**
       * NOTE:- any operation on this page will now be done on slicedItems (unless there is a very specific need)
       * converting a list of items whose types are defined in `ItemTypes` to renderable format.
       *
       * ## representing items by type
       * eg. ["component", "product-card", "product-card","product-card","product-card","product-card","product-card"]
       * lets say `itemsRenderConfig.columnCount` = 3
       * [
       *    "component",
       *    "sliced-flex-cards": ["product-card", "product-card","product-card"],
       *    "sliced-flex-cards": ["product-card", "product-card","product-card"],
       * ]
       *
       * this function treats `product-card` and `product-card-skeleton` as similar objects.
       * to add/remove skeleton loaders, we need to add/remove them from listData
       */
      const slicedItems = useMemo(() => {
        let resultantPageData: FlattenRowDataType[] = [];
        const indexedListData = listData?.map((item, idx) => {
          // indexedListData will have an index key for every item so that the items can be tracked after shuffling in slicing phase
          item.index = idx;
          return item;
        });

        for (let index = 0; index < indexedListData?.length; index++) {
          // whenever a card is found, make sets of cards for rendering in a row
          if (isItemCardType(indexedListData[index].type)) {
            const cardRowsObj = generateCardRows(indexedListData, index);
            index = cardRowsObj.updatedIndex;
            resultantPageData = [...(resultantPageData || []), ...(cardRowsObj.cardRows || [])]
          }
          resultantPageData.push(indexedListData[index]);
        }

        return resultantPageData;
      }, [listData]);

      /**
       * takes a list of card items and convert then into groups of cards to render in each row.
       * number of items in each row is defined by `itemsRenderConfig.columnCount`,
       */
      function generateCardRows(indexedListData: FlattenRowDataType[], index: number): {cardRows: FlattenRowDataType[], updatedIndex: number} {
        const resultantPageData: FlattenRowDataType[] = [];
        let rowData: FlattenRowDataType[] = [];
        while (isItemCardType(indexedListData[index]?.type)) {
          let rowId = `${index}`;
          for (
            let rowItems = 0;
            rowItems < itemsRenderConfig.columnCount;
            rowItems++
          ) {
            if (!indexedListData[index]) {
              break;
            }
            if (isItemCardType(indexedListData[index].type)) {
              rowData.push(indexedListData[index]);
              rowId = `${rowId}-${indexedListData[index].id}`;
              index += 1;
            } else {
              break;
            }
          }
          resultantPageData.push({
            type: 'sliced-flex-cards',
            data: rowData,
            id: rowId,
          });
          rowData = [];
        }
        return {
          cardRows: resultantPageData,
          updatedIndex: index
        };
      }

      // extracting the `scrollToIndex` from Virtuoso component and exposing it to parent for the auto scroll functionality
      useImperativeHandle(virtualisedGridListReference, () => {
        return {
          scrollToIndex: handleScrollToIndex,
        };
      });

      /**
       * since every row is either a `sliced-flex-cards` or a `component`,
       * we calculate the index where an element to scroll is lying after converting to sliced flex.
       */
      const handleScrollToIndex = (indexToScroll) => {
        let idexToScrollAfterSlicing: number | undefined = undefined;

        for (let index = 0; index <= slicedItems?.length; index++) {
          const item = slicedItems[index] as FlattenRowDataType;
          if (item?.type === 'sliced-flex-cards') {
            (item?.data as FlattenRowDataType[])?.forEach((sliceItem) => {
              if (sliceItem.index === indexToScroll) {
                idexToScrollAfterSlicing = index;
              }
            });
          } else {
            if (indexToScroll === item?.index) {
              idexToScrollAfterSlicing = index;
            }
          }
        }

        if (idexToScrollAfterSlicing || idexToScrollAfterSlicing === 0) {
          // offset to place scrolled item in center
          virtuosoGridRef?.current?.scrollToIndex?.({
            index: idexToScrollAfterSlicing,
            align: 'center',
            offset: 200,
          });
        }
      };

      const handleRangeChanged = (range) => {
        const previousRange = cloneDeep(rowChangeTrackerRef.current);
        const newRange = {}

        const startIndex = range?.startIndex;
        const endIndex = range?.endIndex;

        for (let index = startIndex; index <= endIndex; index++) {
          // track all indexes currently on screen
          newRange[index] = 1;
          // exclude intersection callback if index present in previous row change
          if (previousRange[index]) {
            continue;
          }
          const item = slicedItems?.[index] as FlattenRowDataType;
          if (!item) {
            break;
          }

          if (item.type === 'sliced-flex-cards') {
            (item?.data as FlattenRowDataType[])?.forEach((element) => {
              element?.intersectionCallback?.();
            });
          } else {
            item?.intersectionCallback?.();
          }
        }
        rowChangeTrackerRef.current = newRange;
      };

      const renderItemContent = useCallback(
        (index: number, listItem: FlattenRowDataType) => {
          if (listItem?.type === 'component') {
            return <listItem.Component />;
          } else if (listItem?.type === 'sliced-flex-cards') {
            return (
              <div
                style={{
                  columnGap: itemsRenderConfig.columnGap,
                  margin: `${itemsRenderConfig.rowGap}px 0`,
                }}
                className="tw-flex tw-flex-row tw-items-start tw-justify-start"
              >
                {listItem?.data?.map((item, idx) => {
                  if (item?.type === 'product-card') {
                    return renderProductCard(item?.index, item?.data);
                  } else if (item?.type === 'product-card-skeleton') {
                    return <Card skeleton skeletonImage={CARD_SKELETON} key={idx} />;
                  }
                })}
              </div>
            );
          }
        },
        [slicedItems]
      );

      return (
        <Virtuoso
          useWindowScroll={useWindowScroll}
          ref={virtuosoGridRef}
          rangeChanged={handleRangeChanged}
          increaseViewportBy={INCREASE_VIEWPORT_BY}
          data={slicedItems}
          defaultItemHeight={defaultItemHeight || 400}
          itemContent={renderItemContent}
          components={{
            Item: ({ children, ...props }) => {
              const itemIndex = props?.['data-index'];
              const correspondingItem = slicedItems?.[itemIndex];
              return (
                <div
                  key={correspondingItem?.id}
                  {...props}
                  // Just to remove an error due zero element height in the internal working of virtuoso
                  style={{ minHeight: '1px' }}
                >
                  {children}
                </div>
              );
            },
            // eslint-disable-next-line react/display-name
            List: React.forwardRef(({ style, children, ...props }: any, ref) => (
              <div
                ref={ref}
                {...props}
                style={{
                  ...style,
                  ...virtualisationContainerStyle,
                }}
              >
                {children}
              </div>
            )),
          }}
        />
      );
    }
  ),
  (prevProps, nextProps) => prevProps?.listData?.length === nextProps?.listData?.length
);

VirtualisedGridList.displayName = 'VirtualisedGridList';
export default VirtualisedGridList;

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getProducts } from '../utils';
import { IntersectionComponent } from './IntersectionComponent';
import {
  resetCatalogsList,
  resetMB5AllSelectedCategoryPaginationConfig,
  setMB5AllCategoryPaginationConfig,
  setMB5SelectedCategoryPaginationConfig,
} from '@/redux/actions';
import { CatalogItem as CatalogProductItem } from './CatalogItem';

import LoaderIcon from '@/components/Common/LoaderIcon';
import { useSSRSelector } from '@/redux/ssrStore';
import { scrollOnPageLoad } from '@/utils/scrollOnPageLoad';
import { CLICKED_ITEM_OBJ } from '@/utils/constants';

let isComponentMounted = false;
let isObserverIntersecting = false;
const timeoutIds = [];
export const AllCategoryItems = (props) => {
  const dispatch = useDispatch();

  const { iteratableCategories, showATCBtn, onItemClick, categorySelected } = props;
  const { store_id } = useSSRSelector((state) => state.storeReducer.store);

  const [shouldLoad, setStartLoad] = useState(false);

  const catalog_items = useSelector((state) => state.catalogReducer.catalog_items);

  const { state, singleCategoryState } = useSelector((state) => ({
    state: state.catalogReducer.mb5PaginationData.allCategoryComponent,
    singleCategoryState: state.catalogReducer.mb5PaginationData.selectedCategoryComponent,
  }));

  const areAllCategoriesItemsLoaded = useMemo(
    () =>
      catalog_items && Object.keys(catalog_items).length === iteratableCategories.length,
    [catalog_items, iteratableCategories]
  );

  useEffect(() => {
    const scrollConfig = scrollOnPageLoad();
    if (scrollConfig.toScroll) {
      try {
        const { y_scroll } = scrollConfig;
        setTimeout(() => {
          sessionStorage.removeItem(CLICKED_ITEM_OBJ);
          window.scrollTo({ top: y_scroll });
        }, 300);
      } catch (err) {
        console.log('timeout time error:', err);
      }
    } else {
      dispatch(resetCatalogsList());
      dispatch(resetMB5AllSelectedCategoryPaginationConfig());
    }
    return () => {
      isComponentMounted = false;
      isObserverIntersecting = false;
    };
  }, []);

  useEffect(() => {
    if (categorySelected === -1 && !areAllCategoriesItemsLoaded) loadProducts();
  }, [categorySelected, iteratableCategories, shouldLoad]);

  const setState = (argu) => dispatch(setMB5AllCategoryPaginationConfig(argu));

  const loadProducts = async () => {
    if (!iteratableCategories?.[state.currentCategoryIndex] || !store_id) return;
    const currentCategory = iteratableCategories[state.currentCategoryIndex];
    state.pageNo = singleCategoryState[currentCategory.id]?.pageNo || state.pageNo;

    const { status, isNextPage } = await getProducts(
      currentCategory.id,
      currentCategory.hasOwnProperty('is_custom_tag') ? 1 : 0,
      state.pageNo,
      store_id
    );
    dispatch(
      setMB5SelectedCategoryPaginationConfig({
        [currentCategory.id]: { pageNo: state.pageNo + 1, isNext: isNextPage },
      })
    );
    /** If status is true then load next, else we maintain the current state which will retry the API call
     * with same configs, when again user focuses on observer component
     */
    if (!status) return;
    const currentCategoryIndex = !isNextPage
      ? state.currentCategoryIndex + 1
      : state.currentCategoryIndex;
    const updatedPayload = {
      currentCategoryIndex,
      isNextPage,
      pageNo: !isNextPage ? 1 : state.pageNo + 1,
      showLoaderComponent: !!iteratableCategories?.[state.currentCategoryIndex],
    };
    setState(updatedPayload);
    if (!isComponentMounted) isComponentMounted = true;
    // Trigger next product loads when the observer is still in view
    if (isObserverIntersecting) {
      const id = setTimeout(triggerProductLoad, 600);
      timeoutIds.push(id);
    }
  };

  const triggerProductLoad = () => setStartLoad((load) => !load);

  const onIntersection = (isIntersecting) => {
    isObserverIntersecting = isIntersecting;
    if (isIntersecting && isComponentMounted) triggerProductLoad();
  };

  const _RenderCategoriesWithItems = () => {
    if (!iteratableCategories?.length) return null;
    return iteratableCategories.map(
      (item) =>
        (item.hasOwnProperty('is_active') && item.is_active === 0) ||
        (!!catalog_items?.[item.id]?.length && (
          <CatalogProductItem
            key={item.id.toString()}
            item={item}
            catalog_items={catalog_items}
            onItemsImageClick={onItemClick}
            showATCBtn={showATCBtn}
          />
        ))
    );
  };

  return (
    <>
      {_RenderCategoriesWithItems()}
      <IntersectionComponent
        show={state.showLoaderComponent}
        onIntersection={onIntersection}
        Component={LoaderIcon}
      />
    </>
  );
};

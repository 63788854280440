import OfferIcon from 'src/assets/svgExports/OfferIcon';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { StripCTA } from '../Common';
import { useDispatch } from 'react-redux';
import { togglePromoDetailModal } from 'src/redux/actions';

const PromoSlide = ({ promoData }) => {
  const dispatch = useDispatch();
  const offerIconSize = IS_DESKTOP ? 15 : 12;

  function openProductDetailsModal() {
    dispatch(
      togglePromoDetailModal({
        show: true,
        promoId: promoData?.id,
      })
    );
  }
  return (
    <div className="!tw-flex tw-items-start tw-justify-between tw-border tw-border-solid tw-border-[#E3E3E3] tw-px-[16px] tw-py-[12px] md:tw-items-center md:tw-px-[24px] md:tw-py-[16px]">
      <div className="tw-flex tw-flex-col tw-gap-[2px]">
        <p className="tw-m-0 tw-text-[12px]/[20px] md:tw-text-[15px]/[20px]">
          Special Offer
        </p>
        <div className="tw-m-0 tw-flex tw-gap-[4px] md:tw-gap-[8px]">
          <span className="tw-mt-[4px] tw-flex md:tw-mt-[2.5px]">
            <OfferIcon height={offerIconSize} width={offerIconSize} />
          </span>
          <div>
            <p className="tw-m-0 tw-text-[12px]/[20px] tw-font-semibold md:tw-text-[16px]/[20px]">
              {promoData?.description}
            </p>
            {!IS_DESKTOP && (
              <p className="tw-m-0 tw-text-[11px]/[20px] tw-text-[#575757]">
                Use Code - {promoData?.code}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="tw-flex tw-gap-[24px]">
        {IS_DESKTOP && (
          <div className="tw-border tw-border-dashed tw-px-[12px] tw-py-[8px] tw-text-[14px]/[20px] tw-font-semibold">
            Use Code - {promoData?.code}
          </div>
        )}
        <StripCTA onClick={openProductDetailsModal} />
      </div>
    </div>
  );
};

export default PromoSlide;

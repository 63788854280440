/**
 * Checks whether sessionStorage has an item clicked data stored.
 * Then verifies whether the duration between clicked time and returning
 * back to home screen is > 30seconds. Then returns whether session storage
 * contains x and y coordinates of clicked item from PDP section.
 * If the session has those, then return the coordinates else return false.
 * This function is currently being used by Home component on page load.
 */

import { CLICKED_ITEM_OBJ } from './constants';

export function scrollOnPageLoad() {
  const currTime = new Date();
  const sessionObject = JSON.parse(sessionStorage?.getItem(CLICKED_ITEM_OBJ));
  if (sessionObject) {
    const expirationTime = sessionObject?.expiresAt;
    if (expirationTime && Date.parse(currTime) < Date.parse(expirationTime)) {
      const y_offset = sessionObject?.itemClickedData?.CLICKED_ITEM_Y_OFFSET;
      const x_offset = sessionObject?.itemClickedData?.CLICKED_ITEM_X_OFFSET;
      if (y_offset && y_offset !== 0) {
        return { toScroll: true, x_scroll: x_offset, y_scroll: y_offset };
      }
    } else {
      sessionStorage.removeItem(CLICKED_ITEM_OBJ);
    }
  }
  return { toScroll: false };
}
